/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

/**
 * @typedef {Object} PendoContextValue
 * @prop {boolean} initialized Pendo agent initialized.
 */

/**
 * @typedef {Object} Company
 * @prop {number} EmpresaId
 * @prop {string|null} EmpresaLicencaDataInicio
 * @prop {number|null} EmpresaLicencaId
 * @prop {string|null} EmpresaNome
 * @prop {string|null} EmpresaPlanoDescricao
 * @prop {number|null} EmpresaPlanoId
 * @prop {string|null} EmpresaPlanoNome
 * @prop {number|null} EmpresaPlanoTipo
 * @prop {number|null} EmpresaPlanoValor
 * @prop {number|null} EmpresaPlanoValorEssencial
 */

/**
 * @typedef {Object} User
 * @prop {string|null} Cargo
 * @prop {string} Email
 * @prop {number} Id
 * @prop {string} Nome
 */

/** @type {PendoContextValue} */
const defaultValue = {
  initialized: false,
};

const PendoContext = React.createContext(defaultValue);

/**
 * Context provider for Pendo.
 * @see {@link https://agent.pendo.io/}
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {Object} props.loggedIn
 */
function PendoProvider({ auth, loggedIn, children }) {
  const [initialized, setInitialized] = React.useState(false);

  const initialize = React.useCallback(
    (user) => {
      if (!user || !window.pendo) return;

      // Uncomment to open debugger window
      // window.pendo.enableDebugging();

      /** @type {User} */
      const { Id, Nome, Email, Cargo } = user;

      /** @type {Company} */
      const { EmpresaId, EmpresaNome } = user;

      window.pendo.initialize({
        visitor: {
          id: Id,
          full_name: Nome,
          email: Email,
          role: Cargo,
        },
        account: {
          id: EmpresaId,
          name: EmpresaNome,
        },
      });
      setInitialized(true);
    },
    [window.pendo],
  );

  /**
   * Shuts down the agent and cleans up all timers and listeners.
   * Upon completion the agent will be able to be re-initialized.
   */
  const teardown = React.useCallback(() => {
    if (!window.pendo || !window.pendo.teardown) return;
    window.pendo.teardown();
    setInitialized(false);
  }, [window.pendo]);

  React.useEffect(() => {
    if (!auth.signed && initialized) {
      teardown();
      return;
    }

    const isAdmin = Boolean(
      loggedIn && loggedIn.MenuAdmin && loggedIn.MenuAdmin.length,
    );

    if (auth.signed && isAdmin && !initialized) {
      initialize(loggedIn.Usuario);
    }
  }, [auth.signed, initialize, loggedIn, teardown]);

  const value = React.useMemo(() => ({ initialized }), [initialized]);

  return (
    <PendoContext.Provider value={value}>{children}</PendoContext.Provider>
  );
}

PendoProvider.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
  loggedIn: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loggedIn: state.loginPayload.loggedIn,
});

export default connect(mapStateToProps)(PendoProvider);
