import { takeLatest, call, put, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { Types } from '../types';
import {
  requestExportacao,
  requestExportacaoZip,
} from '../../../services/request';

import { toast } from 'react-toastify';
// eslint-disable-next-line import/named
import {
  carregarDownloadResponse,
  carregarDownloadArquivoResponse,
  carregarDownloadArquivosResponse,
  carregarDownloadParaQuemResponse,
} from './actions';

export function* carregarDownloads({ payload }) {
  const params = {
    url: '/download',
    method: 'GET',
    queryParams: {
      ...payload.params.params,
    },
  };

  try {
    const response = yield call(requestExportacao, params);
    const { data } = response;
    yield put(carregarDownloadResponse(data));
  } catch (error) {
    yield put(carregarDownloadResponse({ success: false }));
  }
}

export function* carregarDownloadsArquivo({ downloadId }) {
  const params = {
    url: `/download/${downloadId}/arquivo`,
    method: 'GET',
  };

  try {
    const response = yield call(requestExportacao, params);

    if (!response || !response.data) {
      toast.error('Ocorreu uma falha ao baixa o arquivo');  
      yield put(carregarDownloadArquivoResponse());
      return;
    }

    if (response.data.success) {
      window.open(response.data.data);
    } else {
      toast.error(response.data.message || 'Ocorreu uma falha ao baixa o arquivo');  
    }

    yield put(carregarDownloadArquivoResponse());
  } catch (error) {
    toast.error('Ocorreu uma falha ao baixa o arquivo');
    yield put(carregarDownloadArquivoResponse());
  }
}

export function* carregarDownloadsArquivos({ downloadsIds }) {
  const params = {
    url: '/download/arquivos',
    method: 'GET',
    queryParams: {
      downloadsIds,
    },
  };

  try {
    const res = yield call(requestExportacaoZip, params);
    if (res.status === 200) {
      const blob = new Blob([res.data], {
        type: 'application/octet-stream',
      });
      FileSaver.saveAs(blob, 'download.zip');
    } else {
      toast.error('Ocorreu uma falha ao baixa os arquivos');
    }

    yield put(carregarDownloadArquivosResponse());
  } catch (error) {
    toast.error('Ocorreu uma falha ao baixa os arquivos');
    yield put(carregarDownloadArquivosResponse());
  }
}

export function* carregarDownloadsParaQuem({ id }) {
  const params = {
    url: `/download/${id}/para-quem`,
    method: 'GET',
  };

  try {
    const response = yield call(requestExportacao, params);
    const { data } = response;
    yield put(carregarDownloadParaQuemResponse(data));
  } catch (error) {
    yield put(carregarDownloadParaQuemResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.CARREGAR_DOWNLOADS_REQUEST, carregarDownloads),
  takeLatest(
    Types.CARREGAR_DOWNLOADS_ARQUIVO_REQUEST,
    carregarDownloadsArquivo,
  ),
  takeLatest(
    Types.CARREGAR_DOWNLOADS_ARQUIVOS_REQUEST,
    carregarDownloadsArquivos,
  ),
  takeLatest(
    Types.CARREGAR_DOWNLOADS_PARA_QUEM_REQUEST,
    carregarDownloadsParaQuem,
  ),
]);
